<template>
  <div class="article" @submit.prevent>
    <div class="tbl_option">
      <!-- <div class="tbl_opt"> -->
      <h5>수주 등록</h5>
      <div class="search_opt">
        <label>매출처</label>
        <div class="input_search">
          <input
            type="text"
            placeholder="매출처 검색"
            :readonly="newData.company_id != null"
            :value="getCompanyInfo(newData.company_id).name"
            disabled
          />
          <button>
            <i class="fa fa-search" @click="showCompanySearch = true"></i>
          </button>
        </div>
      </div>
    </div>
    <div class="tbl_head">
      <span>주문내역</span>
    </div>
    <div class="mes_tbl_wrap">
      <table
        class="mes_tbl"
        :class="{ new: newData.sales_products.length > 0 }"
      >
        <colgroup>
          <col
            v-for="(n, index) in newData.sales_products.length > 0 ? 13 : 12"
            :key="index"
          />
        </colgroup>
        <thead>
          <tr>
            <th>제품명</th>
            <th>규격</th>
            <th>과세여부</th>
            <th>단위</th>
            <th>수량</th>
            <th>박스</th>
            <th>총 수량</th>
            <th>단가</th>
            <th>공급가</th>
            <th>부가세</th>
            <th>할인액</th>
            <th>매출액</th>
            <th v-if="newData.sales_products.length > 0">삭제</th>
          </tr>
        </thead>
        <tbody>
          <tr
            v-for="(sales_product, index) in newData.sales_products"
            :key="index"
          >
            <td class="text_left">
              {{
                sales_product.parent_product_id != null
                  ? getProductInfo(sales_product.parent_product_id).name
                  : getProductInfo(sales_product.product_id).name || ''
              }}
              <br v-if="sales_product.parent_product_id != null" />
              {{
                sales_product.parent_product_id != null
                  ? `▶ ${getProductInfo(sales_product.product_id).name}`
                  : ''
              }}
            </td>
            <td class="text_left">
              {{ getProductInfo(sales_product.product_id).standard || '' }}
            </td>
            <td>
              {{
                getProductInfo(sales_product.product_id).tax == true
                  ? '과세'
                  : '면세'
              }}
            </td>
            <td>
              {{ getUnitName(sales_product.unit_id) || '' }}
            </td>
            <!-- 수량 -->
            <td>
              <input
                id="quantity"
                type="text"
                :value="$makeComma(sales_product.quantity)"
                min="0"
                inputmode="decimal"
                @input="
                  $inputNumber($event, sales_product, 'quantity');
                  checkAndReplaceChildQuantity(sales_product);
                "
                :disabled="sales_product.parent_product_id != null"
              />
            </td>
            <td>
              <my-selectric
                :id="`box_unit_selectric${index}`"
                :watch="sales_product.box_unit_id"
                :options="box_options"
                :index="index"
                @refresh="checkAndReplaceChildQuantity(sales_product)"
                :commit="'setNewBoxUnitIdToSales'"
                :state="
                  sales_product.parent_product_id != null ? 'disabled' : ''
                "
              >
              </my-selectric>
            </td>
            <!-- 총수량 -->
            <td class="text_right">
              {{
                $makeComma(
                  $makeNumber(sales_product.quantity) *
                    getBoxQuantity(sales_product.box_unit_id),
                )
              }}
            </td>
            <!-- 단가 -->
            <td>
              <input
                id="unit_price"
                type="text"
                :value="
                  sales_product.parent_product_id != null
                    ? '-'
                    : $makeComma(sales_product.cost)
                "
                inputmode="decimal"
                @input="$inputNumberInt($event, sales_product, 'cost')"
                :disabled="sales_product.parent_product_id != null"
              />
            </td>
            <!-- @input="typingCost($event, index)" -->
            <!-- 공급가 -->
            <td class="text_right">
              {{
                sales_product.parent_product_id != null
                  ? '-'
                  : `₩ ${$makeComma(
                      calSupplyTax(
                        getCompanyInfo(newData.company_id).vat,
                        getProductInfo(sales_product.product_id).tax,
                        $makeNumber(sales_product.quantity) *
                          getBoxQuantity(sales_product.box_unit_id),
                        $makeNumber(sales_product.cost),
                      ).supply,
                    )}`
              }}
            </td>
            <td class="text_right">
              {{
                sales_product.parent_product_id != null
                  ? '-'
                  : `₩ ${$makeComma(
                      calSupplyTax(
                        getCompanyInfo(newData.company_id).vat,
                        getProductInfo(sales_product.product_id).tax,
                        $makeNumber(sales_product.quantity) *
                          getBoxQuantity(sales_product.box_unit_id),
                        $makeNumber(sales_product.cost),
                      ).tax,
                    )}`
              }}
            </td>
            <td>
              <input
                type="text"
                :value="
                  sales_product.parent_product_id != null
                    ? '-'
                    : $makeComma(sales_product.discount)
                "
                @keypress="onlyNumber($event)"
                inputmode="decimal"
                @input="
                  $inputNumber($event, sales_product, 'discount');
                  sales_product.collect_value =
                    calSupplyTax(
                      getCompanyInfo(newData.company_id).vat,
                      getProductInfo(sales_product.product_id).tax,
                      $makeNumber(sales_product.quantity) *
                        getBoxQuantity(sales_product.box_unit_id),
                      $makeNumber(sales_product.cost),
                    ).total - $makeNumber(sales_product.discount);
                  newData.total_discount =
                    newData.sales_products.length > 0
                      ? calTotalPrice(
                          newData.sales_products,
                          $makeNumber(getCompanyInfo(newData.company_id).vat),
                          true,
                        ).total_discount
                      : 0;
                "
                :disabled="sales_product.parent_product_id != null"
              />
            </td>

            <td>
              <input
                type="text"
                :value="
                  sales_product.parent_product_id != null
                    ? '-'
                    : $makeComma(
                        calSupplyTax(
                          getCompanyInfo(newData.company_id).vat,
                          getProductInfo(sales_product.product_id).tax,
                          $makeNumber(sales_product.quantity) *
                            getBoxQuantity(sales_product.box_unit_id),
                          $makeNumber(sales_product.cost),
                        ).total - $makeNumber(sales_product.discount),
                      )
                "
                inputmode="decimal"
                @blur="
                  $event =>
                    ($event.target.value = $makeComma(
                      calSupplyTax(
                        getCompanyInfo(newData.company_id).vat,
                        getProductInfo(sales_product.product_id).tax,
                        $makeNumber(sales_product.quantity) *
                          getBoxQuantity(sales_product.box_unit_id),
                        $makeNumber(sales_product.cost),
                      ).total - $makeNumber(sales_product.discount),
                    ))
                "
                @keydown.enter="
                  $event => reCalCost(sales_product, $event.target.value)
                "
                @keypress="onlyNumber($event)"
                @input="$inputNumberIntNoTarget($event)"
                :disabled="sales_product.parent_product_id != null"
              />
            </td>
            <td v-if="newData.sales_products.length > 0">
              <button
                v-if="sales_product.parent_product_id == null"
                class="tbl_delete_btn"
                @click="deleteProduct(sales_product, index)"
              >
                delete
              </button>
            </td>
          </tr>

          <tr v-show="newData.company_id != null">
            <td>
              <button @click="showProductSearch = true">
                <img src="@/assets/images/icon/icon-floating-search.png" />
              </button>
            </td>
            <td></td>
            <td></td>
            <td></td>
            <td></td>
            <td></td>
            <td></td>
            <td></td>
            <td></td>
            <td></td>
            <td></td>
            <td></td>
            <td v-if="newData.sales_products.length > 0"></td>
          </tr>
        </tbody>
      </table>
    </div>
    <div class="date_options">
      <div class="receiving_info">
        <div>
          <div class="input_text">
            <label>합계액</label>
            <input
              type="text"
              placeholder=""
              readonly
              :value="
                newData.sales_products.length > 0
                  ? '₩ ' +
                    calTotalPrice(
                      newData.sales_products,
                      getCompanyInfo(newData.company_id).vat,
                      true,
                    ).total_cost
                  : ''
              "
            />
          </div>
          <div class="input_text">
            <label>비과세</label>
            <input
              type="text"
              placeholder=""
              readonly
              :value="
                newData.sales_products.length > 0
                  ? '₩ ' +
                    calTotalPrice(
                      newData.sales_products,
                      getCompanyInfo(newData.company_id).vat,
                      true,
                    ).non_tax_total_cost
                  : ''
              "
            />
          </div>
        </div>
        <div>
          <div class="input_text">
            <label>할인액</label>
            <input
              id="total_discount"
              type="text"
              inputmode="decimal"
              placeholder=""
              :readonly="!newData.sales_products.length > 0"
              :value="
                newData.sales_products.length > 0
                  ? calTotalPrice(
                      newData.sales_products,
                      getCompanyInfo(newData.company_id).vat,
                      true,
                    ).total_discount
                  : ''
              "
              @keydown.enter="
                reCalDiscount(
                  $event,
                  newData.sales_products,
                  getCompanyInfo(newData.company_id).vat,
                )
              "
              @keypress="onlyNumber($event)"
              @input="$inputNumberInt($event, newData, 'total_discount')"
            />
            <button
              @click="
                reCalDiscount(
                  $event,
                  newData.sales_products,
                  getCompanyInfo(newData.company_id).vat,
                )
              "
              class="btn_sub2"
            >
              적용
            </button>
          </div>
          <div class="input_text">
            <label>과세</label>
            <input
              type="text"
              placeholder=""
              readonly
              :value="
                newData.sales_products.length > 0
                  ? '₩ ' +
                    calTotalPrice(
                      newData.sales_products,
                      getCompanyInfo(newData.company_id).vat,
                      true,
                    ).tax_total_cost
                  : ''
              "
            />
          </div>
        </div>
        <div>
          <div class="input_text">
            <label>매출액</label>
            <input
              id="sales_cost"
              type="text"
              placeholder=""
              readonly
              :value="
                newData.sales_products.length > 0
                  ? '₩ ' +
                    calTotalPrice(
                      newData.sales_products,
                      getCompanyInfo(newData.company_id).vat,
                      true,
                    ).sales_cost
                  : ''
              "
            />
          </div>
        </div>
      </div>
      <div class="date_set">
        <div class="input_text">
          <label>납품요청일</label>
          <input
            type="date"
            id="setDate"
            min=""
            placeholder=""
            :value="newData.delivery_date"
            @change="setDeliveryDate($event)"
          />
        </div>
        <div class="input_text">
          <label>수주일</label>
          <input
            type="date"
            id="today"
            placeholder=""
            :value="newData.input_date"
            @change="setInputDate($event)"
          />
        </div>
      </div>
      <!-- <div class="input_text">4
        <input type="text" placeholder="N" disabled />
      </div> -->
      <div class="text_set">
        <div class="input_text">
          <label>비고</label>
          <textarea
            type="text"
            placeholder="수주 내용"
            v-model="newData.detail"
          ></textarea>
        </div>
      </div>
      <div class="btn_wrap">
        <button class="btn_sub2" @click="submitForm">수주등록</button>
      </div>
    </div>
    <resource-search
      v-if="showProductSearch"
      :filter_type="2"
      :groupType="2"
      @onclose="showProductSearch = false"
      @onselect="selectProduct($event)"
    ></resource-search>
    <company-search
      v-if="showCompanySearch"
      :search_type="1"
      @onclose="showCompanySearch = false"
      @onselect="pushNewCompany($event)"
    ></company-search>
  </div>
</template>

<script>
import FetchMixin from '@/mixins/fetch';
import ModalMixin from '@/mixins/modal';
import SpinnerMixin from '@/mixins/spinner';
import CompanySearch from '@/layouts/components/search-popup/CompanySearch';
import ResourceSearch from '@/layouts/components/search-popup/ResourceSearch';
import MySelectric from '@/layouts/components/MySelectric';
import PriceMixin from '@/mixins/price';
import { mapGetters } from 'vuex';
import { yyyymmdd } from '@/utils/func';

export default {
  mixins: [ModalMixin, SpinnerMixin, FetchMixin, PriceMixin],
  components: {
    MySelectric,
    CompanySearch,
    ResourceSearch,
  },
  data() {
    return {
      showCompanySearch: false,
      showProductSearch: false,

      // showDateInput: false,
    };
  },
  computed: {
    ...mapGetters({
      box_options: 'getBoxForSelectric',
      plan_sales_type_id: 'getPlanSalesType',
      newData: 'getNewDataFromSales',
      companys: 'getCompany',
      products: 'getProduct',
      units: 'getUnitCodes',
      boxs: 'getBoxsNotSorted',
    }),
    checkTotalCostValid() {
      const calPrice = this.$makeNumber(
        this.calTotalPrice(
          this.lodash.clonedeep(this.newData.sales_products),
          this.getCompanyInfo(this.newData.company_id).vat,
          true,
        ).total_cost,
      );
      if (calPrice <= 2147483647) {
        return true;
      } else {
        return false;
      }
    },
  },
  methods: {
    checkAndReplaceChildQuantity(parent_product) {
      const parent = this.findInfoFromId(
        this.products,
        parent_product.product_id,
      );

      if (parent.product_group_yn && parent.product_group_type_id == 2) {
        const sumProudctQuantity = this.newData.sales_products
          .filter(x => x.product_id == parent_product.product_id)
          .map(x =>
            this.$decimalMul(
              this.$makeNumber(x.quantity),
              this.getBoxQuantity(x.box_unit_id),
            ),
          )
          .reduce((a, b) => this.$decimalAdd(a, b), 0);

        this.newData.sales_products = this.newData.sales_products.map(x => {
          if (x.parent_product_id == parent_product.product_id) {
            x.quantity = this.$decimalMul(
              sumProudctQuantity,
              parent.product_group_list.find(
                y => y.member_product_id == x.product_id,
              ).quantity,
            );
          }
          return x;
        });
      }
    },
    reCalCost(row, newSalesValue) {
      const total_value =
        this.$makeNumber(newSalesValue) + this.$makeNumber(row.discount);
      console.log('total_value', total_value);
      const company_vat = this.companys.find(
        x => x.id == this.newData.company_id,
      ).vat;
      const product_tax = row.tax_id;
      let rowQuantity = this.$decimalMul(
        this.$makeNumber(row.quantity),
        row.box_unit_id != null ? this.getBoxQuantity(row.box_unit_id) : 1,
      );
      if (rowQuantity === 0) {
        row.quantity = 1;
        rowQuantity = 1;
      }

      if (company_vat != 1 || product_tax == 2) {
        row.cost = 0;
        row.cost = this.$makeNumber(
          this.$decimalDiv(total_value, this.$makeNumber(rowQuantity)).toFixed(
            0,
          ),
        );
      } else {
        const new_total_value = this.$makeNumber(
          this.$decimalDiv(total_value, 1.1).toFixed(0),
        );
        row.cost = 0;
        row.cost = this.$makeNumber(
          this.$decimalDiv(
            new_total_value,
            this.$makeNumber(rowQuantity),
          ).toFixed(0),
        );
      }
    },
    setInputDate(e) {
      if (e.target.value != '') {
        if (e.target.value > this.newData.delivery_date) {
          this.openOneButtonModal(
            '수주일 오류',
            `납품요청일(${this.newData.delivery_date}) 이후의<br>날짜를 선택할 수 없습니다.`,
          );
          e.target.value = this.newData.input_date;
        } else {
          this.newData.input_date = e.target.value;
        }
        this.FETCH_SALES();
      } else {
        this.openOneButtonModal('날짜 선택', '날짜를 선택해주세요.');
      }
    },

    setDeliveryDate(e) {
      if (e.target.value != '') {
        if (e.target.value < this.newData.input_date) {
          this.openOneButtonModal(
            '납품요청일 오류',
            `수주일(${this.newData.input_date}) 이전의<br>날짜를 선택할 수 없습니다.`,
          );
          e.target.value = this.newData.delivery_date;
        } else {
          this.newData.delivery_date = e.target.value;
        }
        this.FETCH_SALES();
      } else {
        this.openOneButtonModal('날짜 선택', '날짜를 선택해주세요.');
      }
    },
    submitForm() {
      if (this.validData()) {
        const payload = this.lodash.clonedeep(this.newData);
        payload.vat_id = this.getCompanyInfo(this.newData.company_id).vat;
        payload.sales_products.forEach(el => {
          el.quantity = this.$makeNumber(el.quantity);
          el.cost = this.$makeNumber(el.cost);
          el.discount = this.$makeNumber(el.discount);
          el.quantity = this.$makeNumber(el.quantity);
          el.tax_id = this.getProductInfo(el.product_id).tax;
          el.supply_value = this.calSupplyTax(
            this.getCompanyInfo(this.newData.company_id).vat,
            this.getProductInfo(el.product_id).tax,
            this.$makeNumber(el.quantity) * this.getBoxQuantity(el.box_unit_id),
            this.$makeNumber(el.cost),
          ).supply;
          el.tax = this.calSupplyTax(
            this.getCompanyInfo(this.newData.company_id).vat,
            this.getProductInfo(el.product_id).tax,
            this.$makeNumber(el.quantity) * this.getBoxQuantity(el.box_unit_id),
            this.$makeNumber(el.cost),
          ).tax;
          el.tax = this.calSupplyTax(
            this.getCompanyInfo(this.newData.company_id).vat,
            this.getProductInfo(el.product_id).tax,
            this.$makeNumber(el.quantity) * this.getBoxQuantity(el.box_unit_id),
            this.$makeNumber(el.cost),
          ).tax;
          el.total_cost = this.calSupplyTax(
            this.getCompanyInfo(this.newData.company_id).vat,
            this.getProductInfo(el.product_id).tax,
            this.$makeNumber(el.quantity) * this.getBoxQuantity(el.box_unit_id),
            this.$makeNumber(el.cost),
          ).total;
        });
        console.log(payload);
        this.showSpinner();
        this.$store
          .dispatch('INSERT_SALES_ALL', payload)
          .then(() => {
            this.openOneButtonModal(
              '등록 성공',
              '성공적으로 수주를 등록하였습니다.',
            );

            this.$store.commit('setNewDataToSales', {
              company_id: null,
              sales_type_id: 1,
              completed: false,
              delivery_date: null,
              input_date: null,
              detail: '',
              sales_products: [],
            });
            var date = new Date();
            this.newData.input_date = yyyymmdd(date);
            var date2 = new Date(date.setDate(date.getDate() + 7));
            this.newData.delivery_date = yyyymmdd(date2);
            this.FETCH_SALES();
          })
          .catch(error => {
            console.log(error);
            this.openOneButtonModal(
              '등록 실패',
              '수주 등록 중 오류가 발생했습니다.',
            );
          })
          .finally(() => {
            this.hideSpinner();
          });
      }
    },
    async FETCH_SALES() {
      this.showSpinner();
      await this.$store
        .dispatch('FETCH_SALES', {
          start_date: this.$store.getters.getStartDateFromSales,
          end_date: this.$store.getters.getEndDateFromSales,
          check_only_not_completed: this.$store.getters
            .getCheckOnlyNotCompletedFromSales,
        })
        .then(() => {})
        .catch(error => {
          console.log(error);
          this.$store.commit('setSales', []);
        })
        .finally(() => {
          this.hideSpinner();
        });
    },
    validData() {
      if (
        $('#sales_cost')[0].value == undefined ||
        $('#sales_cost')[0].value.length < 1
      ) {
        this.openOneButtonModal(
          '등록 불가',
          '반드시 하나 이상의 수주품목을 등록하여주십시오.',
        );
        return false;
      } else if ($('#quantity')[0].value == 0) {
        this.openOneButtonModal('등록 불가', '수량은 최소 1이상이어야 합니다.');
      } else if (!this.checkTotalCostValid) {
        this.openOneButtonModal(
          '합계액 초과',
          '1회 수주 최대 금액은<br />20억 이상 수주할 수 없습니다.',
        );
        return false;
      } else {
        if (String($('#sales_cost')[0].value).includes('-')) {
          this.openOneButtonModal(
            '등록 불가',
            '매출액은 최소 0원 이상이어야 합니다.',
          );
          return false;
        }
        return true;
      }
    },
    selectProduct(arg) {
      const groupProudctChk = this.findInfoFromId(this.products, arg.id)
        .product_group_yn;

      if (
        groupProudctChk &&
        this.lodash
          .clonedeep(this.newData.sales_products)
          .find(x => x.product_id == arg.id) != undefined
      ) {
        console.log('안들어오니?');
        this.openOneButtonModal(
          '이미 등록된 세트제품',
          '세트제품은 수주당 하나만 등록할 수 있습니다.',
        );
        return;
      } else {
        const findChildIndex = this.newData.sales_products.findIndex(
          x => x.parent_product_id == arg.id,
        );
        if (findChildIndex == -1) {
          this.newData.sales_products.push({
            product_id: arg.id,
            unit_id: arg.stock_unit_id,
            box_unit_id: arg.box_unit_id,
            standard: arg.standard,
            quantity: 0,
            cost: 0, //단가
            supply_value: 0, //공급가
            tax: 0, //부가세
            total_cost: 0, //합계액
            discount: '0', //단일 할인액
            tax_id: arg.tax,
            parent_product_id: null,
          });
        } else {
          this.newData.sales_products.splice(findChildIndex, 0, {
            product_id: arg.id,
            unit_id: arg.stock_unit_id,
            box_unit_id: arg.box_unit_id,
            standard: arg.standard,
            quantity: 0,
            cost: 0, //단가
            supply_value: 0, //공급가
            tax: 0, //부가세
            total_cost: 0, //합계액
            discount: '0', //단일 할인액
            tax_id: arg.tax,
            parent_product_id: null,
          });
        }

        const setProductChk = this.findInfoFromId(this.products, arg.id);
        if (
          setProductChk.product_group_yn == true &&
          setProductChk.product_group_type_id == 2 &&
          this.newData.sales_products.find(
            x => x.parent_product_id == arg.id,
          ) == undefined
        ) {
          setProductChk.product_group_list.map(x => {
            const setMemberProduct = this.findInfoFromId(
              this.products,
              x.member_product_id,
            );

            this.newData.sales_products.push({
              product_id: setMemberProduct.id,
              unit_id: setMemberProduct.stock_unit_id,
              box_unit_id: null,
              standard: setMemberProduct.standard,
              quantity: 0,
              cost: 0, //단가
              supply_value: 0, //공급가
              tax: 0, //부가세
              total_cost: 0, //합계액
              discount: '0', //단일 할인액
              tax_id: setMemberProduct.tax,
              parent_product_id: x.group_product_id,
            });
          });
        }

        this.showProductSearch = false;
      }
    },
    getBoxQuantity(id) {
      let hit = this.boxs.find(x => x.id == id);
      return hit != undefined ? hit.quantity : 1;
    },
    getProductInfo(id) {
      let hit = this.products.find(x => x.id == id);
      return hit != undefined ? hit : '';
    },
    getCompanyInfo(id) {
      let hit = this.companys.find(x => x.id == id);
      return hit != undefined ? hit : '';
    },
    getUnitName(id) {
      let hit = this.units.find(x => x.id == id);
      return hit != undefined ? hit.name : '';
    },
    pushNewCompany(arg) {
      this.newData.company_id = arg.id;
      this.showCompanySearch = false;
    },
    deleteProduct(sales_product, index) {
      this.newData.sales_products.splice(index, 1);

      const setProductChk = this.findInfoFromId(
        this.products,
        sales_product.product_id,
      );
      if (
        setProductChk.product_group_yn &&
        setProductChk.product_group_type_id == 2 &&
        this.newData.sales_products.find(
          x => x.product_id == sales_product.product_id,
        ) == undefined
      ) {
        this.newData.sales_products = this.newData.sales_products.filter(
          x => x.parent_product_id != sales_product.product_id,
        );
      }
    },
  },
  async created() {
    if (this.plan_sales_type_id == undefined) {
      await this.FETCH('FETCH_SALES_TYPE', '판매 유형');
    }
    if (this.box_options.length < 2) {
      await this.FETCH('FETCH_BOX', '박스');
    }
    if (this.products.length < 1) {
      await this.FETCH('FETCH_PRODUCT_WITH_COMPANY', '제품');
    }
    if (this.companys.length < 1) {
      await this.FETCH('FETCH_COMPANY', '거래처');
    }
    if (this.units.length < 1) {
      await this.FETCH('FETCH_UNIT', '단위');
    }

    if (this.newData.input_date == null) {
      var date = new Date();
      this.newData.input_date = yyyymmdd(date);
      var date2 = new Date(date.setDate(date.getDate() + 7));
      this.newData.delivery_date = yyyymmdd(date2);
    }
    this.newData.sales_type_id = this.plan_sales_type_id.id;
  },
};
</script>

<style></style>
